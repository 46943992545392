/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
//import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// reactstrap components
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import axios ,{post} from 'axios'; 
//import { useHistory } from "react-router-dom";

class Register extends React.Component {

  state = {
    isopendropdowndirect:false,
    directdropdowntext:'Forwarder',
    companynameregister:[],
    billtopartychoosen:'',
    forwardinglist:[],
    forwardingchoosen:'',
    fullnametext:'',
    phonumbertext:'',
    jobpositiontext:'',
    confirmpasswordtext:'',
    emailtext:'',
    contacttext:'',
    checkagreeornot:false,
    alerterrormessage:'',
    errorsubmit:false,
    fullnameinvalid:false,
    billtopartyinvalid:false,
    forwardinginvalid:false,
    usernameinvalid:false,
    passwordinvalid:false,
    confirmpasswordinvalid:false,
    emailinvalid:false,
    contactinvalid:false,
    checkagreeornotinvalid:false,
    onchangeprofilepicture:'',
    linkthatredirect:'',
    profileimageurl:"http://mipe.inniocapital.com/api/miperegister.php?",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    linkurl:"http://mipe.inniocapital.com/api/miperegister.php?",
  };
  


  componentDidMount(){
   // this.setState({forwardinglist:[{title:"Forwarder"},{title:"Direct"}]})
    this.loadcompanylist()
  }


  loadcompanylist = () =>{
    
    var urlencoded = new URLSearchParams();
    let link = this.state.linkurl + 'mode=admin-getcompanylist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      console.log(json.companylist[4])
      if(json.success == 1){
        
            console.log(json)
            this.setState({companynameregister:[]});   
             var lengthemployee = Object.keys(json.companylist).length;
             for(let i=0;i<lengthemployee;i++){             
            
              //status need do
               this.setState({companynameregister:[...this.state.companynameregister,
                { no:i+1,
                  companyname:json.companylist[i].Companyname,
                  companylink:json.companylist[i].companylink,
                  title:json.companylist[i].Companyname,
                },            
              ]})
              
              
             

             
              }
            }        
               
         else{
        console.log(json)
        lengthemployee = 0
      }
    
     })
   
  }


  functionsubmitmiperegister = (value) =>{
 
  this.setState({companyvalue:value})
 // console.log()
  }




submitregisternewaccount = () =>{
  //const history = useHistory();
  //console.log(this.state.companyvalue)
   var lengthemployee = Object.keys(this.state.companynameregister).length;
  for(let i=0;i<lengthemployee;i++){
    //console.log(this.state.companynameregister[i].companyname)
    if(this.state.companynameregister[i].companyname == this.state.companyvalue){
       this.setState({linkthatredirect:this.state.companynameregister[i].companylink})
      // console.log(this.state.companynameregister[i].companylink)
       //update data
  var urlencoded = new URLSearchParams();
  urlencoded.append("companyname",this.state.companyvalue);
  urlencoded.append("name",this.state.fullnametext);
  urlencoded.append("phone",this.state.phonumbertext);
  urlencoded.append("job",this.state.jobpositiontext);
  let link = this.state.linkurl +'mode=admin-inserttransaction'
  fetch(link, {
    method: 'POST', 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body:urlencoded
  })
  .then(response => response.json())
  .then(json => {
    console.log(json.success)
    
    if(json.success == 1){    
      window.location.href = this.state.companynameregister[i].companylink;
    //  window.open(this.state.companynameregister[i].companylink)
    }
    else{
        //this.setState({loading:false}) 
      console.log(json)
    }
  
   })
  //then go to the link
}
    }

}

 


  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-1">
            <div className="text-center text-muted mb-4 ">
                <small>Check in Register</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
            
              <Form role="form">
              {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
                    <FormGroup>
                <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.companynameregister}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" ,backgroundColor:"white"}}
                        onInputChange={(event,value) =>this.functionsubmitmiperegister(value)}
                        renderInput={(params) => <TextField {...params} label="@Company Name" variant="outlined" error={this.state.billtopartyvalidation} helperText={this.state.billtopartyvalidationmessage} />}
                        disableClearable={true}
                      />
                </FormGroup>
              <FormGroup >
                  <InputGroup className="input-group-alternative mb-3 border border-gray mt-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input invalid={this.state.fullnameinvalid} placeholder="Full Name" type="text" value={this.state.fullnametext} onChange = {(e) => this.setState({fullnametext: e.target.value })} />
                  </InputGroup>
                </FormGroup>

                <FormGroup >
                  <InputGroup className="input-group-alternative mb-3 border border-gray mt-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input invalid={this.state.fullnameinvalid} placeholder="Phone Number" type="text" value={this.state.phonumbertext} onChange = {(e) => this.setState({phonumbertext: e.target.value })} />
                  </InputGroup>
                </FormGroup>

                <FormGroup >
                  <InputGroup className="input-group-alternative mb-3 border border-gray mt-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-briefcase-24" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input invalid={this.state.fullnameinvalid} placeholder="Job Position" type="text" value={this.state.jobpositiontext} onChange = {(e) => this.setState({jobpositiontext: e.target.value })} />
                  </InputGroup>
                </FormGroup>

              
              
              
              
           
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={(e) => this.submitregisternewaccount()}>
                    Check In MIIPBA
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
         
        </Col>

     
      </>
    );
  }
}

export default Register;
