/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class AdminNavbar extends React.Component {

  state={
    name:sessionStorage.getItem('Name'),
    ref:window.sessionStorage.getItem('Ref'),
    profileimageurl:sessionStorage.getItem('Profilepictureurl'),
    linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
    //linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
    have2account:sessionStorage.getItem('have2account'),
    usernameaccount1:sessionStorage.getItem('Username'),
    nameaccount1: sessionStorage.getItem('Name'),
    refacccount1: sessionStorage.getItem('Ref'),
    levelaccount1: sessionStorage.getItem('Level'),
    billtopartyaccount1: sessionStorage.getItem('Billtoparty'),
    directorforwarderaccount1: sessionStorage.getItem('Directorforwarder'),
    emailaccount1: sessionStorage.getItem('Email'),
    contactaccount1: sessionStorage.getItem('Contact'),
    profilepictureurlaccount1: sessionStorage.getItem('Profilepictureurl'),

    usernameaccount2:sessionStorage.getItem('Usernameaccount2'),
    nameaccount2: sessionStorage.getItem('Nameaccount2'),
    refacccount2: sessionStorage.getItem('Refaccount2'),
    levelaccount2: sessionStorage.getItem('Levelaccount2'),
    billtopartyaccount2: sessionStorage.getItem('Billtopartyaccount2'),
    directorforwarderaccount2: sessionStorage.getItem('Directorforwarderaccount2'),
    emailaccount2: sessionStorage.getItem('Emailaccount2'),
    contactaccount2: sessionStorage.getItem('Contactaccount2'),
    profilepictureurlaccount2: sessionStorage.getItem('Profilepictureurlaccount2'),
  }

  secondbilltopartyclick = () =>{  
    sessionStorage.setItem('Username', this.state.usernameaccount2);
    sessionStorage.setItem('Name', this.state.nameaccount2);
    sessionStorage.setItem('Ref', this.state.refacccount2);
    sessionStorage.setItem('Level', this.state.levelaccount2);
    sessionStorage.setItem('Billtoparty', this.state.billtopartyaccount2);
    sessionStorage.setItem('Directorforwarder', this.state.directorforwarderaccount2);
    sessionStorage.setItem('Email', this.state.emailaccount2);
    sessionStorage.setItem('Contact', this.state.contactaccount2);
    sessionStorage.setItem('Profilepictureurl', this.state.profilepictureurlaccount2);


    sessionStorage.setItem('Usernameaccount2', this.state.usernameaccount1);
    sessionStorage.setItem('Nameaccount2', this.state.nameaccount1);
    sessionStorage.setItem('Refaccount2', this.state.refacccount1);
    sessionStorage.setItem('Levelaccount2', this.state.levelaccount1);
    sessionStorage.setItem('Billtopartyaccount2', this.state.billtopartyaccount1);
    sessionStorage.setItem('Directorforwarderaccount2', this.state.directorforwarderaccount1);
    sessionStorage.setItem('Emailaccount2', this.state.emailaccount1);
    sessionStorage.setItem('Contactaccount2', this.state.contactaccount1);
    sessionStorage.setItem('Profilepictureurlaccount2', this.state.profilepictureurlaccount1);
    window.location.reload(false);

  }
  
  render() {
    const imgurl = this.state.imageurllink + sessionStorage.getItem('Profilepictureurl')
    
    console.log(imgurl)
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="ml-3 h2 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                {window.location.href == "http://crm.mewahtrans.com.my/admin/user-profile" ?
                  <DropdownToggle className="pr-0" nav>
                  {/* <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src = {imgurl}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block"> */}
                      <span className="mb-0 text-sm font-weight-bold">
                        Settings
                      </span>
                    {/* </Media>
                  </Media> */}
                </DropdownToggle>
                :
                <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      width="40"
                      height="40"
                      src = {imgurl}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {this.state.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              
                }
               

                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                
                  {this.state.have2account == 1 ?
                   <DropdownItem to="/admin/user-profile" onClick={(e)=>this.secondbilltopartyclick(e)}>
                   <i className="ni ni-circle-08" />
                  <span>Switch To {this.state.billtopartyaccount2}</span>
                 </DropdownItem>
                  :
                  null
                  }


                  <DropdownItem divider />
                  <DropdownItem href="#pablo" to="/auth/login" tag={Link} >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
